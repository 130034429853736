import { createTheme } from "@mui/material/styles";

const subscriptionColors = {
  free: "#4A90E2", // A more vibrant blue
  coach: "#50C878", // A rich emerald green
  team: "#9370DB", // A luxurious purple
};

const lightTheme = {
  primary: {
    main: subscriptionColors.free,
    light: "#6BA5E7",
    dark: "#3A72B4",
  },
  secondary: {
    main: "#FF6B6B", // A coral red for contrast
    light: "#FF8E8E",
    dark: "#E65555",
  },
  error: {
    main: "#FF4757", // A vivid red
  },
  warning: {
    main: "#FFA502", // A warm amber
  },
  info: {
    main: "#2ED1A2", // A fresh teal
  },
  success: {
    main: "#5CDB95", // A lush green
  },
  background: {
    default: "#F8F9FA", // A crisp, clean off-white
    paper: "#FFFFFF", // Pure white for contrast
    paperSecondary: "#F0F2F5", // Subtle grey for depth
    paperDark: "#E9ECEF", // Slightly darker for hierarchy
    dark: "#212529", // Deep charcoal for strong contrast
  },
  text: {
    primary: "#212529", // Near-black for readability
    secondary: "#6C757D", // A sophisticated grey
  },
  gender: {
    male: "#4A90E2", // Matching the primary blue
    female: "#FF69B4", // A softer, more luxurious pink
  },
  border: {
    main: "#E0E0E0", // A subtle, elegant border color
  },
};

const darkTheme = {
  primary: {
    main: "#6BA5E7", // A lighter, more vibrant blue for dark mode
    light: "#8EBDEE",
    dark: "#5689C0",
  },
  secondary: {
    main: "#FF8E8E", // A softer coral for dark mode
    light: "#FFA7A7",
    dark: "#E67777",
  },
  error: {
    main: "#FF6B6B", // A softer red for dark mode
  },
  warning: {
    main: "#FFD166", // A softer amber for dark mode
  },
  info: {
    main: "#45E0B0", // A brighter teal for dark mode
  },
  success: {
    main: "#7AE582", // A brighter green for dark mode
  },
  background: {
    default: "#1F2937", // Softer deep blue-gray instead of near-black
    paper: "#293548", // Slightly lighter blue-gray for contrast
    paperSecondary: "#374151", // Medium blue-gray for depth
    paperDark: "#111827", // Darker shade but not pure black
    dark: "#0F172A", // Very dark blue-gray instead of pure black
  },
  text: {
    primary: "#F3F4F6", // Off-white instead of pure white for reduced eye strain
    secondary: "#D1D5DB", // Lighter gray for better visibility on dark backgrounds
  },
  gender: {
    male: "#6BA5E7", // Matching the primary blue
    female: "#FF69B4", // Keeping the same pink for consistency
  },
  border: {
    main: "#4B5563", // A lighter border color for dark mode
  },
};

const createAppTheme = (mode = "light") => {
  return createTheme({
    palette: {
      mode,
      ...(mode === "light" ? lightTheme : darkTheme),
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      h1: {
        fontWeight: 300,
        letterSpacing: "-0.01562em",
      },
      h2: {
        fontWeight: 300,
        letterSpacing: "-0.00833em",
      },
      h3: {
        fontWeight: 400,
        letterSpacing: "0em",
      },
      h4: {
        fontWeight: 400,
        letterSpacing: "0.00735em",
      },
      h5: {
        fontWeight: 400,
        letterSpacing: "0em",
      },
      h6: {
        fontWeight: 500,
        letterSpacing: "0.0075em",
      },
      subtitle1: {
        letterSpacing: "0.00938em",
      },
      subtitle2: {
        letterSpacing: "0.00714em",
      },
      body1: {
        letterSpacing: "0.00938em",
      },
      body2: {
        letterSpacing: "0.01071em",
      },
      button: {
        letterSpacing: "0.02857em",
        textTransform: "uppercase",
      },
      caption: {
        letterSpacing: "0.03333em",
      },
      overline: {
        letterSpacing: "0.08333em",
        textTransform: "uppercase",
      },
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "28px",
            textTransform: "none",
            fontWeight: 500,
          },
          contained: {
            boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .03)",
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor:
              mode === "light" ? lightTheme.border.main : darkTheme.border.main,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            height: 3,
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            borderRadius: 8,
            boxShadow: "0 8px 16px 0 rgba(0,0,0,0.1)",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 12,
            boxShadow: "0 6px 12px 0 rgba(0,0,0,0.05)",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              borderRadius: 8,
            },
          },
        },
      },
    },
  });
};

export default createAppTheme;
export { lightTheme, darkTheme };
