import React, { useState, useMemo } from "react";
import { Typography, Grid, Box, TextField } from "@mui/material";
import useApiService from "../../../../api/apiService";
import { ComponentWrapper } from "../../../../components/utils";
import { TableAlumni } from "./tableAlumni";

function AlumniSection() {
  const [searchTerm, setSearchTerm] = useState("");
  const apiService = useApiService();

  const { data: athletes } = apiService.useAthletes();

  const filteredAlumni = useMemo(() => {
    return (
      athletes
        ?.filter(
          (athlete) =>
            athlete.grade === "alumni" &&
            `${athlete.firstName} ${athlete.lastName}`
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => a.firstName.localeCompare(b.firstName)) || []
    );
  }, [athletes, searchTerm]);

  return (
    <ComponentWrapper>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Box p={2} display="flex" flexDirection="column" alignItems="stretch">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <TextField
                label="Search Alumni"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ flexGrow: 1, marginRight: "10px" }}
              />
            </Box>
          </Box>
          {filteredAlumni.length > 0 ? (
            <TableAlumni alumni={filteredAlumni} />
          ) : (
            <Box p={4} textAlign="center">
              <Typography variant="h6" color="textSecondary">
                No alumni found. Promote senior athletes to alumni using the
                "Increment all years by 1" option.
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </ComponentWrapper>
  );
}

export { AlumniSection };
