import React from "react";
import { Box, Grid } from "@mui/material";
import { AthletePod } from "../../../../components/pods/athletePod/athletePod";

const TableAlumni = ({ alumni }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid>
        {alumni.map((athlete) => (
          <Grid item xs={12} key={athlete.id}>
            <AthletePod
              athlete={athlete}
              showFields={[
                "name",
                "email",
                "gender",
                "athleteTypes",
                "tffrsLink",
                "lastSeen",
              ]}
              options={false} // No options menu for alumni
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export { TableAlumni };
