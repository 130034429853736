import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Box,
  LinearProgress,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import useApiService from "../../../../api/apiService";
import ViewModal from "../../../../components/viewModal/viewModal";

const NextYear = ({ open, handleClose, isIncrement = true }) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [processedAthletes, setProcessedAthletes] = useState([]);
  const [completed, setCompleted] = useState(false);
  const [seniors, setSeniors] = useState([]);
  const [selectedGraduation, setSelectedGraduation] = useState({});
  const [showSeniorDialog, setShowSeniorDialog] = useState(false);
  const [freshmen, setFreshmen] = useState([]);
  const [showFreshmenDialog, setShowFreshmenDialog] = useState(false);
  const [alumni, setAlumni] = useState([]);
  const [showAlumniDialog, setShowAlumniDialog] = useState(false);
  const [selectedAlumniGrade, setSelectedAlumniGrade] = useState({});
  // State to control main modal visibility
  const [hideMainModal, setHideMainModal] = useState(false);

  const { useAthletes, useUpdateAthlete } = useApiService();
  const { data: athletes, refetch } = useAthletes();
  const updateAthlete = useUpdateAthlete();

  // Hide main modal when any dialog is shown
  useEffect(() => {
    setHideMainModal(
      showSeniorDialog || showFreshmenDialog || showAlumniDialog
    );
  }, [showSeniorDialog, showFreshmenDialog, showAlumniDialog]);

  useEffect(() => {
    if (athletes && open) {
      if (isIncrement) {
        const seniorsList = athletes.filter(
          (athlete) => athlete.grade === "senior" || athlete.grade === "12th"
        );
        setSeniors(seniorsList);

        // Initialize selected graduation options
        const initialGraduation = {};
        seniorsList.forEach((senior) => {
          initialGraduation[senior.id] = "5th_year"; // Default to 5th year
        });
        setSelectedGraduation(initialGraduation);
      } else {
        // For decrement operation
        const freshmenList = athletes.filter(
          (athlete) => athlete.grade === "freshman" || athlete.grade === "9th"
        );
        setFreshmen(freshmenList);

        const alumniList = athletes.filter(
          (athlete) => athlete.grade === "alumni"
        );
        setAlumni(alumniList);

        // Initialize selected alumni grade options
        const initialAlumniGrade = {};
        alumniList.forEach((alum) => {
          initialAlumniGrade[alum.id] = "senior"; // Default to senior
        });
        setSelectedAlumniGrade(initialAlumniGrade);
      }
    }
  }, [athletes, open, isIncrement]);

  const getNextGrade = (currentGrade, athleteId) => {
    if (isIncrement) {
      // For seniors and 12th graders, use the selected option
      if (currentGrade === "senior" || currentGrade === "12th") {
        return selectedGraduation[athleteId] || "5th_year";
      }

      const gradeMap = {
        freshman: "sophomore",
        sophomore: "junior",
        junior: "senior",
        senior: "5th_year", // Default, but will be overridden by selection
        "5th_year": "6th_year",
        "6th_year": "alumni",
        "6th": "7th",
        "7th": "8th",
        "8th": "9th",
        "9th": "10th",
        "10th": "11th",
        "11th": "12th",
        "12th": "alumni", // Default, but will be overridden by selection
        alumni: "alumni",
        other: "other",
      };

      return gradeMap[currentGrade] || "other";
    } else {
      // When decrementing
      if (currentGrade === "alumni") {
        return selectedAlumniGrade[athleteId] || "senior";
      }

      const gradeMap = {
        sophomore: "freshman",
        junior: "sophomore",
        senior: "junior",
        "5th_year": "senior",
        "6th_year": "5th_year",
        "7th": "6th",
        "8th": "7th",
        "9th": "8th",
        "10th": "9th",
        "11th": "10th",
        "12th": "11th",
        alumni: "senior", // Default, but will be overridden by selection
        other: "other",
      };

      return gradeMap[currentGrade] || currentGrade;
    }
  };

  const handleUpdateAthletes = async () => {
    if (!athletes || athletes.length === 0) return;

    // Handle confirmation dialogs for special cases
    if (isIncrement && seniors.length > 0 && !showSeniorDialog) {
      setShowSeniorDialog(true);
      return;
    }

    if (!isIncrement && freshmen.length > 0 && !showFreshmenDialog) {
      setShowFreshmenDialog(true);
      return;
    }

    if (!isIncrement && alumni.length > 0 && !showAlumniDialog) {
      setShowAlumniDialog(true);
      return;
    }

    setLoading(true);
    setProcessedAthletes([]);
    setProgress(0);

    const processed = [];
    const totalAthletes = athletes.length;

    for (let i = 0; i < totalAthletes; i++) {
      const athlete = athletes[i];

      // Skip freshmen when decrementing if they shouldn't be changed
      if (
        !isIncrement &&
        (athlete.grade === "freshman" || athlete.grade === "9th")
      ) {
        continue;
      }

      // Skip alumni when incrementing - they stay as alumni
      if (isIncrement && athlete.grade === "alumni") {
        continue;
      }

      const currentGrade = athlete.grade;
      const nextGrade = getNextGrade(currentGrade, athlete.id);

      try {
        // Include all existing athlete fields to prevent data loss
        // Only update the grade field
        await updateAthlete.mutateAsync({
          id: athlete.id,
          data: {
            grade: nextGrade,
            // Keep all other fields unchanged
            firstName: athlete.firstName,
            lastName: athlete.lastName,
            email: athlete.email,
            birthDate: athlete.birthDate,
            phone: athlete.phone,
            fScore: athlete.fScore,
            athleteTypes: athlete.athleteTypes,
            gender: athlete.gender,
            tffrsLink: athlete.tffrsLink,
          },
        });

        processed.push({
          id: athlete.id,
          name: `${athlete.firstName} ${athlete.lastName}`,
          oldGrade: currentGrade,
          newGrade: nextGrade,
          success: true,
        });
      } catch (error) {
        processed.push({
          id: athlete.id,
          name: `${athlete.firstName} ${athlete.lastName}`,
          oldGrade: currentGrade,
          newGrade: nextGrade,
          success: false,
          error: error.message,
        });
      }

      setProcessedAthletes([...processed]);
      setProgress(((i + 1) / totalAthletes) * 100);

      // Add a small delay to prevent overwhelming the server
      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    setLoading(false);
    setCompleted(true);
    refetch();
  };

  const handleGraduationChange = (athleteId, value) => {
    setSelectedGraduation((prev) => ({
      ...prev,
      [athleteId]: value,
    }));
  };

  const handleAlumniGradeChange = (athleteId, value) => {
    setSelectedAlumniGrade((prev) => ({
      ...prev,
      [athleteId]: value,
    }));
  };

  const handleSeniorDialogClose = () => {
    setShowSeniorDialog(false);
  };

  const handleSeniorDialogConfirm = () => {
    setShowSeniorDialog(false);
    handleUpdateAthletes();
  };

  const handleFreshmenDialogClose = () => {
    setShowFreshmenDialog(false);
  };

  const handleFreshmenDialogConfirm = () => {
    setShowFreshmenDialog(false);
    handleUpdateAthletes();
  };

  const handleAlumniDialogClose = () => {
    setShowAlumniDialog(false);
  };

  const handleAlumniDialogConfirm = () => {
    setShowAlumniDialog(false);
    handleUpdateAthletes();
  };

  const handleCloseAndReset = () => {
    setLoading(false);
    setProgress(0);
    setProcessedAthletes([]);
    setCompleted(false);
    setShowSeniorDialog(false);
    setShowFreshmenDialog(false);
    setShowAlumniDialog(false);
    handleClose();
  };

  const formatGrade = (grade) => {
    const gradeDisplay = {
      freshman: "Freshman",
      sophomore: "Sophomore",
      junior: "Junior",
      senior: "Senior",
      "5th_year": "5th Year",
      "6th_year": "6th Year",
      "6th": "6th Grade",
      "7th": "7th Grade",
      "8th": "8th Grade",
      "9th": "9th Grade",
      "10th": "10th Grade",
      "11th": "11th Grade",
      "12th": "12th Grade",
      alumni: "Alumni",
      other: "Other",
    };

    return gradeDisplay[grade] || grade;
  };

  return (
    <>
      <ViewModal open={open && !hideMainModal} onClose={handleCloseAndReset}>
        <Box sx={{ width: "100%", p: 2 }}>
          <Typography variant="h5" gutterBottom>
            {isIncrement
              ? "Promote Athletes to Next Year"
              : "Rollback Athletes to Previous Year"}
          </Typography>

          <Typography variant="body1" paragraph>
            {isIncrement
              ? "This will update all athletes' grades to the next year. For example, Freshmen will become Sophomores, Seniors will become 5th Year, etc."
              : "This will roll back all athletes' grades to the previous year. For example, Sophomores will become Freshmen, Seniors will become Juniors, etc. Note: Freshmen will remain unchanged."}
          </Typography>

          {!loading && !completed && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateAthletes}
              sx={{ mt: 2, mb: 2 }}
            >
              {isIncrement
                ? "Start Promoting Athletes"
                : "Start Rolling Back Athletes"}
            </Button>
          )}

          {loading && (
            <Box sx={{ mt: 3, mb: 2 }}>
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{ mb: 1 }}
              />
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body2">Processing athletes...</Typography>
                <Typography variant="body2">{Math.round(progress)}%</Typography>
              </Box>
              <Box display="flex" justifyContent="center" mt={2}>
                <CircularProgress size={24} />
              </Box>
            </Box>
          )}

          {processedAthletes.length > 0 && (
            <Paper
              elevation={3}
              sx={{ mt: 3, maxHeight: "50vh", overflow: "auto" }}
            >
              <List dense>
                {processedAthletes.map((athlete, index) => (
                  <React.Fragment key={athlete.id}>
                    <ListItem>
                      <ListItemText
                        primary={athlete.name}
                        secondary={
                          <Typography variant="body2" component="span">
                            {formatGrade(athlete.oldGrade)} →{" "}
                            {formatGrade(athlete.newGrade)}
                          </Typography>
                        }
                      />
                      <Chip
                        label={athlete.success ? "Success" : "Failed"}
                        color={athlete.success ? "success" : "error"}
                        size="small"
                      />
                    </ListItem>
                    {index < processedAthletes.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          )}

          {completed && (
            <Box sx={{ mt: 3, textAlign: "center" }}>
              <Typography variant="h6" color="primary" gutterBottom>
                {isIncrement ? "Promotion Complete!" : "Rollback Complete!"}
              </Typography>
              <Typography variant="body1" paragraph>
                {processedAthletes.filter((a) => a.success).length} of{" "}
                {processedAthletes.length} athletes were successfully{" "}
                {isIncrement ? "promoted" : "rolled back"}.
              </Typography>
              <Button
                variant="contained"
                onClick={handleCloseAndReset}
                sx={{ mt: 1 }}
              >
                Close
              </Button>
            </Box>
          )}
        </Box>
      </ViewModal>

      {/* Senior Graduation Options Dialog */}
      <Dialog
        open={showSeniorDialog}
        onClose={handleSeniorDialogClose}
        maxWidth="md"
        fullWidth
        sx={{ zIndex: 1500 }} // Higher z-index to ensure visibility
      >
        <DialogTitle>Senior Graduation Options</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select what happens to your seniors/12th graders next year:
          </DialogContentText>
          <Box sx={{ mt: 2, maxHeight: "50vh", overflow: "auto" }}>
            {seniors.map((senior) => (
              <Box key={senior.id} sx={{ mb: 3 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {senior.firstName} {senior.lastName} (
                  {formatGrade(senior.grade)})
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    value={selectedGraduation[senior.id] || "5th_year"}
                    onChange={(e) =>
                      handleGraduationChange(senior.id, e.target.value)
                    }
                  >
                    <FormControlLabel
                      value="5th_year"
                      control={<Radio />}
                      label="Promote to 5th Year"
                    />
                    <FormControlLabel
                      value="alumni"
                      control={<Radio />}
                      label="Graduate to Alumni"
                    />
                  </RadioGroup>
                </FormControl>
                <Divider sx={{ mt: 1 }} />
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSeniorDialogClose}>Cancel</Button>
          <Button
            onClick={handleSeniorDialogConfirm}
            variant="contained"
            color="primary"
          >
            Continue with Promotions
          </Button>
        </DialogActions>
      </Dialog>

      {/* Freshmen Warning Dialog */}
      <Dialog
        open={showFreshmenDialog}
        onClose={handleFreshmenDialogClose}
        maxWidth="sm"
        fullWidth
        sx={{ zIndex: 1500 }} // Higher z-index to ensure visibility
      >
        <DialogTitle>Freshmen Notice</DialogTitle>
        <DialogContent>
          <DialogContentText>
            When rolling back grades, freshmen/9th grade athletes will remain
            unchanged. The following athletes will not be modified:
          </DialogContentText>
          <Box sx={{ mt: 2, maxHeight: "30vh", overflow: "auto" }}>
            <List dense>
              {freshmen.map((freshman) => (
                <ListItem key={freshman.id}>
                  <ListItemText
                    primary={`${freshman.firstName} ${freshman.lastName}`}
                    secondary={formatGrade(freshman.grade)}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFreshmenDialogClose}>Cancel</Button>
          <Button
            onClick={handleFreshmenDialogConfirm}
            variant="contained"
            color="primary"
          >
            Continue with Rollback
          </Button>
        </DialogActions>
      </Dialog>

      {/* Alumni Options Dialog */}
      <Dialog
        open={showAlumniDialog}
        onClose={handleAlumniDialogClose}
        maxWidth="md"
        fullWidth
        sx={{ zIndex: 1500 }} // Higher z-index to ensure visibility
      >
        <DialogTitle>Alumni Rollback Options</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select what grade to assign to your alumni athletes when
            rolling back:
          </DialogContentText>
          <Box sx={{ mt: 2, maxHeight: "50vh", overflow: "auto" }}>
            {alumni.map((alum) => (
              <Box key={alum.id} sx={{ mb: 3 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {alum.firstName} {alum.lastName} ({formatGrade(alum.grade)})
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    value={selectedAlumniGrade[alum.id] || "senior"}
                    onChange={(e) =>
                      handleAlumniGradeChange(alum.id, e.target.value)
                    }
                  >
                    <FormControlLabel
                      value="senior"
                      control={<Radio />}
                      label="Roll back to Senior"
                    />
                    <FormControlLabel
                      value="5th_year"
                      control={<Radio />}
                      label="Roll back to 5th Year"
                    />
                  </RadioGroup>
                </FormControl>
                <Divider sx={{ mt: 1 }} />
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlumniDialogClose}>Cancel</Button>
          <Button
            onClick={handleAlumniDialogConfirm}
            variant="contained"
            color="primary"
          >
            Continue with Rollback
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NextYear;
